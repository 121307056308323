<template>
  <div class="change-pass">
    <top-bar back backroute="security" :title="$app.secure.clean($n.t('16.1'))"></top-bar>
    <div class="container">
      <n-form>
        <n-items>
          <div class="item">
            <n-input type="password" :danger="$var('m1')" v-bind="$form.input('oldPassword')"
                     name="oldPass" :title="$app.secure.clean($n.t('16.2'))" :placeholder="$app.secure.clean($n.t('16.3'))" />
            <div class="eye" @click="changeInputType('oldPass')">
              <n-icon :icon="$var('oldPasshide') ? 'eye' : 'close-eye'" />
            </div>
          </div>

          <div class="item">
            <n-input type="password" :danger="$var('m2')" v-bind="$form.input('newPassword')"
                     name="tryOldPass" :title="$app.secure.clean($n.t('16.4'))" :placeholder="$app.secure.clean($n.t('16.5'))" />
            <div class="eye" @click="changeInputType('tryOldPass')">
              <n-icon :icon="$var('tryOldPasshide') ? 'eye' : 'close-eye'" />
            </div>
          </div>

          <div class="item">
            <n-input type="password" :danger="$var('m3')" v-bind="$form.input('confirmPassword')"
                     name="newPass" :title="$app.secure.clean($n.t('16.6'))" :placeholder="$app.secure.clean($n.t('16.5'))" />
            <div class="eye" @click="changeInputType('newPass')">
              <n-icon :icon="$var('newPasshide') ? 'eye' : 'close-eye'" />
            </div>
          </div>
          <n-button wide color="yellow" @click="save()"><t name="16.7" /></n-button>
        </n-items>
      </n-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageChangePassword',
  data() {
    return {
      user: $app.auth.user(),
    }
  },
  created() {
    this.$var('newPasshide', true)
    this.$var('tryOldPasshide', true)
    this.$var('oldPasshide', true)
    this.$form.init({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    this.$form.rules({
      oldPassword: [ 'required', ],
      newPassword: [ 'required', ],
      confirmPassword: [ 'required', ],
    })
  },
  methods: {
    save() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.user.editPassword($app.auth.user().id, this.$form.get()).then(() => {
          $app.auth.logout()
        }).catch((response) => {
          this.$var('m1', $app.secure.clean($n.t(response.response.data.data.oldPassword)) || '')
          this.$var('m2', $app.secure.clean($n.t(response.response.data.data.newPassword)) || '')
          this.$var('m3', $app.secure.clean($n.t(response.response.data.data.confirmPassword)) || '')
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        if (!this.$form.get('oldPassword')) {
          this.$var('m1', $app.secure.clean($n.t('20.9')))
        }
        if (!this.$form.get('newPassword')) {
          this.$var('m2', $app.secure.clean($n.t('20.9')))
        }
        if (!this.$form.get('confirmPassword')) {
          this.$var('m3', $app.secure.clean($n.t('20.9')))
        }
      }
    },
    changeInputType(name) {
      const input = document.getElementById(name)

      if (input) {
        if (input.type === 'text') {
          input.type = 'password'
          this.$var(name + 'hide', true)
        } else {
          input.type = 'text'
          this.$var(name + 'hide', false)
        }
        input.focus()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.change-pass{
  .n-form{
    .item{
      width: 100%;
      position: relative;

      .eye{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 9px;
        bottom: 7px;
        height: 30px;
        width: 30px;
      }
    }
  }
}
</style>
